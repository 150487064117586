<template>
  <div>
    <div class="head">
      <div class="box">
        <img src="../../assets/img/business/img-bg.png" class="img" />
      </div>
    </div>
    <div class="content1">
      <div class="top">
        <div class="title">产品与案例展示</div>
        <div class="line"></div>
        <div class="title1">Product and case presentation</div>
      </div>
      <div class="bom">
        <div class="title">
          <div class="item" :class="{ active: index == curIndex }" v-for="(item, index) in businessList" :key="index" @click="tabbtn(index)">
            <div class="text">{{ item.title }}</div>
          </div>
        </div>
        <div class="content">
          <div class="box">
            <Carousel v-model="curIndex" loop arrow="never" dots="none">
              <CarouselItem v-for="(item, index) in businessList" :key="index">
                <div class="item-box">
                  <!--                  <img class="img" :src="item.img" />-->
                  <div class="text">{{ item.text }}</div>
                </div>
              </CarouselItem>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="top">
        <div class="title">优秀案例</div>
        <div class="line"></div>
        <div class="title1">EXCELLENT CASES</div>
      </div>
      <div class="bom">
        <!-- <div class="bom-left">
          <div class="item" @click="showBox(-1)">
            <div
              class="item-one"
              @mouseleave="mouseLeave0"
              @mouseover="mouseOver0"
            >
              <div class="item-top">
                <img class="img" src="../../assets/img/business/swiper/1-02.png" />
                <div v-if="!curIndex0" class="title">等级医院评审管理系统</div>
              </div>
              <div
                v-if="curIndex0"
                class="box animated"
                :class="{ fadeIn: curIndex0 }"
              >
                <div class="box-cen">
                  <img src="../../assets/img/business/case-icon.png" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="bom-right">
          <div class="item" v-for="(item, index) in caseList" :key="item.index" @click="showBox(item.index)">
            <div class="item-one" @mouseleave="mouseLeave" @mouseover="mouseOver(item.index)">
              <div class="item-top">
                <img :src="item.img" class="img" />
                <div v-if="index != curIndex1" class="title">{{ item.title }}</div>
              </div>
              <div v-if="index == curIndex1" class="box animated" :class="{ fadeIn: index == curIndex1 }">
                <div class="box-cen">
                  <img src="../../assets/img/business/case-icon.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mc-box" v-if="showModal">
        <div class="modalBox animated bounceIn">
          <div class="modal-head">
            <div></div>
            <img src="../../assets/img/business/icon-del.png" class="del" @click="del" />
          </div>
          <modelSwiper1 v-if="show0" :swiper="swiper0"></modelSwiper1>
          <modelSwiper1 v-if="show1" :swiper="swiper1"></modelSwiper1>
          <modelSwiper1 v-if="show2" :swiper="swiper2"></modelSwiper1>
          <modelSwiper1 v-if="show3" :swiper="swiper3"></modelSwiper1>
          <modelSwiper1 v-if="show4" :swiper="swiper4"></modelSwiper1>
          <modelSwiper1 v-if="show5" :swiper="swiper5"></modelSwiper1>
        </div>
      </div>
    </div>
    <floatBar></floatBar>
  </div>
</template>

<script>
import floatBar from "../../components/FloatBar";
import modelSwiper1 from "../../components/modelSwiper1.vue";
export default {
  metaInfo: {
    title: "南昌多安科技有限公司，多安智慧医务一体化平台，多安不良事件上报平台，多安（杭州）医疗咨询，多安评审系统",
    meta: [
      {
        name: "Business",
        content: "业务与案例",
      },
    ],
  },
  components: { floatBar, modelSwiper1, },
  props: {},
  data() {
    return {
      businessList: [
        {
          index: 0,
          title: "医务一体化平台",
          text: "医务一体化管理平台，是医院医务管理和医疗管理的重要组成部分，通过医务管理的信息化建设，建立数据库，将医院医务日常工作管理、医疗技术管理、医疗质量管理、医师日常行为管控、科室管控等医务科日常管理工作分门别类，实现工作流管理、流程化管理、工作闭环管理、溯源管理、精准化管控、Al人工智能等多种功能，为提高工作效率提供了保证。",
        },
        {
          index: 1,
          title: "等级医院评审管理系统",
          text: "\n" +
            "\n" +
            "  多安医院评审信息系统根据卫生部颁布的相关评审细则要求及结合医院的\n" +
            "\n" +
            "实际自评自查工作研发；有效的减轻繁重的人工评审准备工作，实现快速发现、快速整改、快速纠正问题，辅助医院管理、出具相关评审报告；操作简单、方便易用。",
        },
        {
          index: 2,
          title: "制度考学审批管理系统",
          text: "辅助医院职能科室高效开展、推进等级评审工作及制度等各类台账汇编归档，考学留痕、直观体现持续改进成效。\n" +
            "\n" +
            "针对评审指标进行全维度监测，出具院科自评报告，实时把控迎检质量。",
        },
        {
          index: 3,
          title: "医院预决算及合同审批管理系统",
          text: "响应”廉洁医院“政策要求\n" +
            "\n" +
            "制定标准及非标准合同模板制定\n" +
            "\n" +
            "审批、验收流程自动化、财务付款有依据。\n" +
            "\n" +
            "合同按类归档，支持医院预决算管理。",
        },
        {
          index: 4,
          title: "医疗指标监管分析系统",

          text: "系统内指标体系应符合《三级医院评审标准（2020年版）福建省实施细则》中第二部分指标或医院自定义指标。\n" +
            "\n" +
            "支持多数据来源，支持手工数据填报数据上传功能\n" +
            "\n" +
            "提供指标图表方式直观展现，支持柱状图、折线图、数据表等多种展现形式。\n" +
            "\n" +
            "支持自动出具自评报告并按照用户使用要求进行模板设定。",
        },
        {
          index: 5,
          title: "不良事件上报管理系统",
          img: require("../../assets/img/business/5.png"),
          text: "支持钉钉移动端上报/审批/统计分析\n" +
            "\n" +
            "支持多院区、全院级不良事件管理\n" +
            "\n" +
            "专业的质控工具，持续提升医疗质量\n" +
            "\n" +
            "先进的指标体系，质量管理更规范。\n" +
            "\n",
        },

      ],
      curIndex: 0,
      caseList: [
        {
          title: "医务一体化平台",
          index: 0,
          img: require("../../assets/img/business/swiper/0-01.jpg"),
        },
        {
          title: "等级医院评审管理系统",
          index: 1,
          img: require("../../assets/img/business/swiper/1-02.png"),
        },
        {
          title: "制度考学审批管理系统",
          index: 2,
          img: require("../../assets/img/business/swiper/2-06.png"),
        },
        {
          title: "医院预决算及合同审批管理系统",
          index: 3,
          img: require("../../assets/img/business/swiper/3-01.png"),
        },
        {
          title: "医疗指标监管分析系统",
          index: 4,
          img: require("../../assets/img/business/swiper/1-22.jpeg"),
        },
        {
          title: "不良事件上报管理系统",
          index: 5,
          img: require("../../assets/img/business/swiper/4-03.jpg"),
        },
      ],
      curIndex1: null,
      curIndex0: false,
      showModal: false,
      // showModal: true,
      show0: false,
      // show0: true,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      swiper0: {
        title: "医务一体化平台",
        imgList: [
          { img: require("../../assets/img/business/swiper/0-01.jpg"), title: "南昌大学附属口腔医院" },
          { img: require("../../assets/img/business/swiper/0-02.jpg"), title: "鹰潭市人民医院" },
          { img: require("../../assets/img/business/swiper/0-03.jpg"), title: "赣州市人民医院" },
        ]
      },
      swiper1: {
        title: "等级医院评审管理系统",
        imgList: [
          { img: require("../../assets/img/business/swiper/1-01.jpg"), title: "台州恩泽医疗集团台州医院" },
          { img: require("../../assets/img/business/swiper/4-06.png"), title: "贵州省兴义市人民医院" },
          { img: require("../../assets/img/business/swiper/1-02.png"), title: "深圳市龙华区妇幼保健院" },
          { img: require("../../assets/img/business/swiper/1-03.jpg"), title: "宁波医疗李惠利医院" },
          { img: require("../../assets/img/business/swiper/1-04.jpg"), title: "嘉兴市第一医院" },
          { img: require("../../assets/img/business/swiper/2-03.png"), title: "台州肿瘤医院" },
          { img: require("../../assets/img/business/swiper/1-06.png"), title: "温州市人民医院" },
          { img: require("../../assets/img/business/swiper/1-07.jpg"), title: "浙江省舟山医院" },
          { img: require("../../assets/img/business/swiper/1-08.jpg"), title: "定西市人民医院" },
          { img: require("../../assets/img/business/swiper/1-09.jpg"), title: "福清市医院" },
          { img: require("../../assets/img/business/swiper/1-10.jpg"), title: "瑞安市人民医院" },
          { img: require("../../assets/img/business/swiper/1-11.png"), title: "衢州市中医院" },
          { img: require("../../assets/img/business/swiper/1-12.jpg"), title: "绍兴市中医院" },
        ],
      },
      swiper2: {
        title: "制度考学审批管理系统",
        imgList: [
          { img: require("../../assets/img/business/swiper/2-01.png"), title: "武义县妇幼保健院" },
          { img: require("../../assets/img/business/swiper/2-02.png"), title: "浙江省龙港市人民医院" },
          { img: require("../../assets/img/business/swiper/2-03.png"), title: "台州肿瘤医院" },
          { img: require("../../assets/img/business/swiper/2-04.png"), title: "天仙骨科医院" },
          { img: require("../../assets/img/business/swiper/2-05.png"), title: "浙江省吴兴区人民医院" },
          { img: require("../../assets/img/business/swiper/2-06.png"), title: "浙江省金华市婺城区人民医院" },
          { img: require("../../assets/img/business/swiper/2-07.png"), title: "浙江省嵊州市人民医院" },
          { img: require("../../assets/img/business/swiper/2-08.png"), title: "浙江省苍南县第三医院" },
          { img: require("../../assets/img/business/swiper/2-09.png"), title: "浙江省金华市第二医院" },
          { img: require("../../assets/img/business/swiper/2-10.png"), title: "平阳中医院" },
          { img: require("../../assets/img/business/swiper/2-11.png"), title: "武义县中医院" },
          { img: require("../../assets/img/business/swiper/2-12.png"), title: "常山县人民医院" },
          { img: require("../../assets/img/business/swiper/2-13.png"), title: "浙江省金华市文荣医院" },

        ],
      },
      swiper3: {
        title: "医院预决算及合同审批管理系统",
        imgList: [
          { img: require("../../assets/img/business/swiper/3-01.png"), title: "常山县人民医院" },
          { img: require("../../assets/img/business/swiper/2-06.png"), title: "浙江省金华市婺城区人民医院" },
          { img: require("../../assets/img/business/swiper/2-07.png"), title: "浙江省嵊州市人民医院" },
        ],
      },
      swiper4: {
        title: "医疗指标监管分析系统",
        imgList: [
          { img: require("../../assets/img/business/swiper/1-02.png"), title: "深圳市龙华区妇幼保健院" },
          { img: require("../../assets/img/business/swiper/1-22.jpeg"), title: "郑州大学第二附属医院" },
          { img: require("../../assets/img/business/swiper/1-23.png"), title: "宁波二院" },
          { img: require("../../assets/img/business/swiper/1-04.jpg"), title: "金华市婺城区第一人民医院" },
          { img: require("../../assets/img/business/swiper/1-09.jpg"), title: "浙江省余姚市中医院" },
          { img: require("../../assets/img/business/swiper/1-13.png"), title: "浙江省金华市妇幼保健院" },
          { img: require("../../assets/img/business/swiper/1-12.jpg"), title: "重庆市万州区妇幼保健院" },
        ],
      },
      swiper5: {
        title: "不良事件上报管理系统",
        imgList: [
          { img: require("../../assets/img/business/swiper/4-01.jpg"), title: "浙江省龙港市人民医院" },
          { img: require("../../assets/img/business/swiper/4-02.png"), title: "赣州市人民医院" },
          { img: require("../../assets/img/business/swiper/4-03.jpg"), title: "义乌市中心医院" },
          { img: require("../../assets/img/business/swiper/4-04.png"), title: "温岭市妇幼保健院" },
          { img: require("../../assets/img/business/swiper/4-05.png"), title: "湖北房县人民医院" },
          { img: require("../../assets/img/business/swiper/4-06.png"), title: "贵州省兴义市人民医院" },
        ],
      },
    };
  },
  watch: {},
  methods: {
    tabbtn(i) {
      this.curIndex = i;
    },
    mouseOver(i) {
      this.curIndex1 = i;
    },
    mouseLeave() {
      this.curIndex1 = null;
    },
    mouseOver0() {
      this.curIndex0 = true;
    },
    mouseLeave0() {
      this.curIndex0 = false;
    },
    showBox(i) {
      this.show0 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
      this.show4 = false;
      this.show5 = false;
      this.show6 = false;
      if (i == 0) {
        this.show0 = true;
      } else if (i == 1) {
        this.show1 = true;
      } else if (i == 2) {
        this.show2 = true;
      } else if (i == 3) {
        this.show3 = true;
      } else if (i == 4) {
        this.show4 = true;
      } else if (i == 5) {
        this.show5 = true;
      } else if (i == 6) {
        this.show6 = true;
      }
      this.showModal = true;
    },
    del() {
      this.showModal = false;
    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  },
  created() {
    this.toTop();
  },
  mounted() { },
};
</script>


<style lang="less" scoped>
.top {
  text-align: center;
  .title {
    color: #000028;
    font-size: 24px;
  }
  .line {
    width: 40px;
    height: 1px;
    background: #ef8200;
    opacity: 1;
    margin: 0 auto;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .title1 {
    font-size: 14px;
    color: #999999;
  }
}
.head {
  width: 100%;
  height: 368px;
  padding-top: 68px;
  .box {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .img {
      width: 100%;
      height: 300px;
      position: absolute;
      z-index: -1;
    }
    .text {
      font-size: 36px;
      font-weight: bold;
      color: #fff;
    }
  }
}
.content1 {
  width: 100%;
  padding-top: 70px;
  .bom {
    margin-top: 60px;
    .title {
      display: flex;
      justify-content: space-around;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 33px;
      .item {
        // width: 240px;
        padding: 0 15px;
        background: #f4f4f4;
        height: 48px;
        text-align: center;
        line-height: 48px;
        cursor: pointer;
        border-radius: 24px;
        color: #6f6f6f;
        font-size: 16px;
      }
      .active {
        background: #ef8200;
        .text {
          color: #fff;
          font-size: 16px;
        }
      }
    }
    .content {
      width: 100%;
      height: 500px;
      // background-color: #ef8200;
      background-image: url("../../assets/img/business/content-img.png");
      background-size: 100%;
      background-repeat: no-repeat;
      .box {
        width: 980px;
        height: 500px;
        margin: 0 auto;
        // background: #ef8200;
        .item-box {
          display: flex;
          justify-content: center;
          align-items: center;
          .img {
            width: 120px;
            height: 120px;
          }
          .text {
            font-size: 20px;
            color: #fff;
            display: flex;
            align-items: center;
            width: 630px;
            height: 400px;
            text-align: center;
          }
        }
      }
    }
  }
}
.content2 {
  padding-top: 70px;
  .bom {
    width: 80%;
    height: 600px;
    margin: 0 auto;
    margin-top: 60px;
    margin-bottom: 90px;
    display: flex;
    .bom-left {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 816px;
        height: 600px;
        cursor: pointer;
        .item-one {
          position: relative;
          width: 816px;
          height: 600px;
          .item-top {
            .img {
              width: 816px;
              height: 600px;
            }
            .title {
              width: 816px;
              height: 46px;
              line-height: 46px;
              background-color: rgba(0, 0, 0, 0.5);
              text-align: center;
              color: #fff;
              font-size: 16px;
              position: absolute;
              bottom: 0;
            }
          }
          .box {
            position: absolute;
            z-index: 99;
            top: 0;
            width: 816px;
            height: 600px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            .box-cen {
              width: 118px;
              height: 118px;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
    }
    .bom-right {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 33.33%;
        height: 300px;
        cursor: pointer;
        .item-one {
          position: relative;
          width: 100%;
          height: 300px;
          .item-top {
            .img {
              width: 100%;
              height: 300px;
            }
            .title {
              width: 100%;
              height: 46px;
              line-height: 46px;
              background-color: rgba(0, 0, 0, 0.5);
              text-align: center;
              color: #fff;
              font-size: 16px;
              position: absolute;
              bottom: 0;
            }
          }
          .box {
            position: absolute;
            z-index: 99;
            top: 0;
            width: 100%;
            height: 300px;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            .box-cen {
              width: 88px;
              height: 88px;
              background-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 60px;
                height: 60px;
              }
            }
          }
        }
      }
    }
  }
  .mc-box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    .modalBox {
      padding: 20px;
      width: 1400px;
      height: 700px;
      background: #fff;
      border-radius: 10px;
      position: fixed;
      margin: auto;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .modal-head {
        display: flex;
        justify-content: space-between;
        .del {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
